























































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import registryService from "@/services/registry.service";
import SerialEmbed from "@/components/SerialEmbed.vue";
import {
  BCard,
  BCardSubTitle,
  BCardText,
  BRow,
  BCol,
  BFormSelect,
  BInputGroup,
  BFormTextarea,
  BFormInput,
  BFormRow,
  BFormCheckbox,
  BButton,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BModal
} from "bootstrap-vue";
import { Serial } from "@/types";
import { AxiosError } from "axios";

@Component({
  components: {
    SerialEmbed,
    BCard,
    BCardSubTitle,
    BCardText,
    BRow,
    BCol,
    BFormSelect,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    BFormRow,
    BFormCheckbox,
    BButton,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BModal
  },
  model: {
    prop: "request",
    event: "change"
  }
})
export default class SerialRequestCard extends Vue {
  @Prop({ type: Object, required: true }) readonly request!: Serial;
  @Prop({ type: Number, default: 0 }) readonly inEdit!: number;
  @Prop({ type: Boolean, default: false }) readonly issueCheckBox!: boolean;
  @Prop({ type: String }) readonly warning!: string;

  private deleteUnlocked = false;
  revisionOptions: string[] = [
    "V0.0",
    "V0.1",
    "V0.2",
    "V1.0",
    "V1.5",
    "V1.6",
    "V1.8",
    "V2.0",
    "V2.1",
    "V2.2",
    "V2.4",
    "VS",
    "VL",
    "VT",
    "VK",
    "VPX"
  ];
  private editedRequest: Serial | null = null;
  private error: string | null = null;
  private showRedditEmbed = false;

  private showTransferModal = false;
  private showConfirmTransferModal = false;
  private serialToTransfer: Serial | {} = {};
  private serialNumberToTransfer = "";

  private updateRequest() {
    const keepStatus = this.request.status;
    this.request.status = "pending";

    registryService
      .updateRequest(
        this.request.userId,
        this.request.created.toString(),
        this.request
      )
      .then(() => {
        this.request.status = keepStatus;
      })
      .catch(e => {
        this.error =
          "Error: " + (e.response.data.errorMessage || e.response.data.message);
        this.$emit("error", this.error);
      });
  }

  private async handleDelete() {
    if (this.deleteUnlocked) {
      const result = await registryService
        .deleteRequest(this.request.userId, this.request.created.toString())
        .catch(e => {
          this.error =
            "Error: " +
            (e.response.data.errorMessage || e.response.data.message);
          this.$emit("error", this.error);
        });
      if (result === "success") {
        this.$emit("delete", this.request);
      }
      this.deleteUnlocked = false;
    } else {
      this.deleteUnlocked = true;
    }
    setTimeout(() => {
      this.deleteUnlocked = false;
    }, 2000);
  }

  private editModeStart() {
    this.editedRequest = this.unescapedRequest;
    this.$emit("changeInEdit", this.inEdit + 1);
  }

  private editModeDone() {
    Object.assign(this.request, this.editedRequest);
    this.editedRequest = null;
    this.updateRequest();
    this.$emit("change", this.editedRequest);
    this.$emit("changeInEdit", this.inEdit - 1);
  }

  private editModeCancel() {
    this.editedRequest = null;
    this.$emit("changeInEdit", this.inEdit - 1);
  }

  private async duplicateRequest() {
    try {
      const newRequest = await registryService.duplicateRequest(
        this.request.userId,
        this.request.created.toString()
      );
      this.$emit("duplicated", newRequest);
    } catch (error) {
      this.error = (error as AxiosError)?.response?.data?.errorMessage ?? 'An unknown error occured.'; //TODO create util for this
    }

  }

  private showNames(serial: Serial) {
    if (serial.reddit_username) {
      if (serial.discord_username) {
        return `${serial.reddit_username} (${serial.discord_username})`;
      } else {
        return serial.reddit_username;
      }
    } else {
      return serial.discord_username;
    }
  }

  private async openConfirmTransferModal() {
    this.serialToTransfer = await registryService.getSerial(this.serialNumberToTransfer);
    this.showConfirmTransferModal = true;
  }

  private async transferSerial() {
    await registryService.adminTransfer(this.request.userId, this.request.created.toString(), this.serialNumberToTransfer, this.request);
    this.$emit("transferred", this.request);
  }

  private static htmlDecode(input: string): string | null {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  private get unescapedRequest(): Serial {
    return Object.fromEntries(
      Object.entries(this.request).map(([k, v]) => [
        k,
        typeof v === "string" ? SerialRequestCard.htmlDecode(v) : v
      ])
    ) as Serial;
  }
}
